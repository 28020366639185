import { Component } from "react";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";

interface OutbrainWidgetProps {
  dataSrc: string;
  dataWidgetId: string;
  store: Store;
}

declare global {
  interface Window {
    OBR: {
      extern: {
        renderSpaWidgets: (options: any) => void;
      };
    };
  }
}

const log = ILog.logger(`OutbrainWidget`);

class OutbrainWidget extends Component<OutbrainWidgetProps, Record<string, unknown>> {
  private mounted = false;
  private rendered = false;

  private isOutbrainAvailable = () => {
    return typeof window !== "undefined" && window.OBR?.extern?.renderSpaWidgets;
  };

  private handleOutbrainSubscription = (subscribe: boolean) => {
    const { onOutbrainLoad } = this.props.store;
    const method = subscribe ? "subscribe" : "unsubscribe";
    onOutbrainLoad[method](this.refreshWidget);
  };

  private refreshWidget = () => {
    if (this.mounted && !this.rendered && this.isOutbrainAvailable()) {
      log.debug("Rendering Outbrain widget");
      window.OBR.extern.renderSpaWidgets(this.props.dataSrc);
      this.rendered = true;
    } else {
      log.debug(
        "Outbrain widget not rendered",
        `Mounted ${this.mounted}`,
        `Rendered ${this.rendered}`,
        `Avail ${this.isOutbrainAvailable()}`,
      );
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.refreshWidget();
    this.handleOutbrainSubscription(true);
  }

  componentWillUnmount() {
    this.handleOutbrainSubscription(false);
  }

  render() {
    const { dataSrc, dataWidgetId } = this.props;
    return <div className="OUTBRAIN" data-src={dataSrc} data-widget-id={dataWidgetId} />;
  }
}

export default OutbrainWidget;
